@import url('https://fonts.googleapis.com/css?family=Proza+Libre');
@import url('https://fonts.googleapis.com/css?family=Changa+One');

$primary: #169fc5; /* MAIN COLOR */
$secondary: #d7702a; /* SECONDARY COLOR */
$gray: #666;
$blk: #222;
$wht: #f8f8f8;
$links: #444;
body {
  font-family: 'Proza Libre', sans-serif;
  color: $gray;
  width: 100%;
}
h1,h2,h3.title, .title {
font-family: 'Changa One', cursive;
}
.navbar {
  margin-bottom: 0;
  z-index: 1030;
}
.logo {
  max-width: 225px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 175px;
  }
  @media (max-width: 767px) {
    max-width: 150px;
  }
}
.navbar-nav {
    margin: 0 -15px;}
.navbar, .navbar-header{
  position: relative;

  .navbar-nav>li>a {
    line-height:68px;
    background: $wht;
    outline: 0;
    &:hover {
      color: #e81852;
    }
    &:focus, &:active {
      color: #777;
    }
    @media (max-width: 991px) {
      line-height: 40px;
    }
    @media (max-width:767px){
      line-height: 10px;
    }
  }
  .navbar-nav>li {
    background: $wht;
  }
  .nav .nav-divider {
    margin: 0px ;
    background: #ccc;
  }
}
.navbar-brand {
    padding: 0px 15px;
}
.modal-dialog{
  width: 250px;
  text-align: center;
  margin:7em auto;
    input[type=submit]{
     padding: 15px 25px;
     width: 100%;
     color:$blk !important;
   }
 }
 .modal-header, .modal-footer{
  background: #333;
  color: #fff;
 }
 input#username{
  margin-bottom: 20px;
 }
 .modal-title {
   color:#fff
 }
 .modal-dialog .btn-default {width:100%; background: #333; border: none; color:#fff}
.hero-info{
  padding: 50px 0px;
  p {
    font-size: 1.3em;
    padding: 0 20px;
  }
}
.hero-quote {
  padding: 100px 0;
  background: url(../img/banner.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: fixed;
    @media(max-width: 1024px){
      background-attachment: scroll;
    }
    @media (max-width: 767px) {
      background-position: 53% 50%;
    }
  h3 {
    font-size: 2em;
    line-height:1.6em;
    color: $wht;
    @media(max-width:767px){
      font-size: 1.2em;
    }
  }
}
.hero-process {
  padding: 250px 0px;
  background: url('../img/banner2.jpg') no-repeat;
  background-size: cover;
  background-position: 50% 10%;
  background-color: #e2e2e2;
  @media (max-width: 767px) {
    padding: 100px 15px;
  }
  h3 {
    color: white;
    font-size: 3em;
    @media (max-width: 767px) {
      font-size: 1.6em;
    }
  }
  p {
    font-size: 1.6em;
    margin: 100px 0;
    color: $blk;
    @media (max-width: 767px){
      margin: 20px 0;
      font-size: 1.2em;
    }

  }
}
.hero-contact {
  background-color: #edeff1;
  padding: 0;
  .col-md-5{
    background-color: $blk;
    padding: 100px 50px;
    color: $wht;
    @media(max-width: 767px){
      padding:40px 20px;
    }
    ul {
      @media (max-width: 480px){
        padding-left: 10px;
      }
    }
    li {
      list-style: none;
      line-height: 36px;
    }
    a {
      text-decoration: none;
      color: #ccc;
      font-weight: 100;
    }
  }
  .col-md-7 {
    padding: 100px 50px;
    @media(max-width: 767px){
      padding:40px 20px;
    }

    p {
      font-size:1.2em;
      line-height:1.5em;
      color:#999;
    }
  }
}
footer {
  text-align: center;
  padding-top: 25px;
  background: $secondary;
  color: $wht;
  a {
    text-decoration: none;
    color: $wht;
    white-space: nowrap;
    &:hover, &:focus, &:active {
      color: $wht;
      text-decoration: none;
    }
  }
}
.my-slider {
  width: 100%;
}
.unslider {
  margin: 0;
  padding: 0;
}
.my-slider .one {
  background: url(../img/sliderDemo1.jpg) no-repeat;
  background-size: cover;
  height: 800px;
  width: 100%;
  background-position: 50% 0%;
  position: relative;
  p {
    color: white;
    font-size: 2.3em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    @media (max-width: 767px) {
      font-size: 1.5em;
    }
  }
  @media (max-width: 1199px) {
    height: 500px;
  }
  @media (max-width: 640px) {
    height: 350px;
  }
  @media (max-width: 568px) {
    height: 300px;
  }

}
.my-slider .two {
  background: url(../img/sliderDemo2.jpg) no-repeat;
  background-size: cover;
  position: relative;
  height: 800px;
  width: 100%;
  background-position: 50% 0%;
  p {
    color: white;
    top: 50%;
    left: 50%;
    position: absolute;
    width: 75%;
    transform: translate(-50%, -50%);
    font-size: 2.3em;
    @media (max-width: 767px) {
      font-size: 1.5em;
    }
  }
  @media (max-width: 1199px) {
    height: 500px;
  }
  @media (max-width: 640px) {
    height: 350px;
  }
  @media (max-width: 568px) {
    height: 300px;
  }

}
.my-slider .three {
  background: url(../img/sliderDemo3.jpg) no-repeat;
  background-size: cover;
  height: 800px;
  position: relative;
  width: 100%;
  background-position: 50% 0%;
  p {
    color: white;
    font-size: 2.3em;
    top: 50%;
    left: 50%;
    position: absolute;
    width: 75%;
    transform: translate(-50%, -50%);
    @media (max-width: 767px) {
      font-size: 1.5em;
    }
  }
  @media (max-width: 1199px) {
    height: 500px;
  }
  @media (max-width: 640px) {
    height: 350px;
  }
  @media (max-width: 568px) {
    height: 300px;
  }
}
.my-slider li {
  width: 100%;
  -webkit-padding-start: 0px;
}

.fixedNav {
  position: fixed;
  width: 100%;
  top: 0;
}
.books {
@media (max-width: 991px) {
    max-width: 400px;
    width: 100%;
}
@media (max-width: 767px) {
  max-width: 250px;
}
}

.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}

input#password, input#username {
  color: black;
  border: 1px solid black;
  @media (max-width: 767px) {
    font-size: 16px;
  }
  &:focus, &:active {
    background: white;
    color: black;
  }
}